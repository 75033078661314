import { AppProgressBar } from "components/app/app-progress-bar";
import { AppRouterCounter } from "components/app/app-router-counter";
import { AppAuth } from "components/app/app-auth";
import { DebugModeProvider } from "components/debug/debug-mode-provider";
import ErrorBoundary from "components/ErrorBoundary";
import { SessionProvider } from "next-auth/react";
import PlausibleProvider from "next-plausible";
import "../styles/app.scss";
import { env } from "env";

export default function MyApp({
  Component,
  pageProps: { session, ...pageProps },
  err,
}) {
  return (
    <div className="root">
      <ErrorBoundary
        type="default"
        errorMessage="Noe har gått galt. Feilen har blitt logget i våre systemer."
      >
        <PlausibleProvider
          domain={env.NEXT_PUBLIC_PLAUSIBLE_DOMAIN}
          trackOutboundLinks={true}
          trackFileDownloads={true}
          trackLocalhost={true}
          enabled={true}
        >
          <SessionProvider
            session={session}
            // Re-fetch session every 2 minutes
            refetchInterval={2 * 60}
            // Re-fetches session when window is focused
            refetchOnWindowFocus={true}
          >
            <DebugModeProvider>
              <AppRouterCounter>
                <AppAuth>
                  <AppProgressBar />
                  <Component {...pageProps} err={err} />
                </AppAuth>
              </AppRouterCounter>
            </DebugModeProvider>
          </SessionProvider>
        </PlausibleProvider>
      </ErrorBoundary>
    </div>
  );
}
