import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

/**
 * Docs: https://env.t3.gg/docs/core
 * Github: https://github.com/t3-oss/t3-env
 */

export const env = createEnv({
  server: {
    ENVIRONMENT: z.enum(["production", "test"]),
    DEBUG: z.coerce.boolean(),

    SANITY_AUTH_TOKEN: z.string(),
    SANITY_PROXY_FETCH_ENABLED: z.string().optional(),
    SANITY_WEBHOOK_SECRET: z.string(),

    SANITY_HTMLRENBLAD_TOKEN: z.string(),

    RENBLAD_PRINT_SECRET: z.string().optional(),
    DOCRAPTOR_API_KEY: z.string().optional(),

    LW_BEARER_TOKEN: z.string().optional(),
    LW_SCHOOL_URL: z.string().url().optional(),
    LW_CLIENT_TOKEN: z.string().optional(),

    VIPPS_CLIENT_ID: z.string().optional(),
    VIPPS_CLIENT_SECRET: z.string().optional(),
    VIPPS_SUBSCRIPTION_KEY: z.string().optional(),
    VIPPS_MERCHANT_SERIAL_NUMBER: z.string().optional(),
    VIPPS_CALLBACK_AUTHORIZATION_TOKEN: z.string().optional(),
    VIPPS_CALLBACK_URL: z.string().url().optional(),
    VIPPS_RETURN_URL: z.string().url().optional(),

    MANDRILL_API_KEY: z.string().optional(),
    MANDRILL_SUBACCOUNT: z.string().optional(),
    MANDRILL_RETURN_PATH_DOMAIN: z.string().optional(),
    MANDRILL_SIGNING_DOMAIN: z.string().optional(),

    EMAIL_VALID_DOMAINS: z.string().optional(),
    EMAIL_VALID_ADDRESSES: z.string().optional(),
    EMAIL_ENABLED: z.coerce.boolean(),

    COURSE_DATABASE_URL: z.string().url().optional(),

    DB_PASSWORD: z.string().optional(),
    DB_USER: z.string().optional(),

    KEYCLOAK_SSR_CONFIG: z.string(),

    NEXTAUTH_SECRET: z.string(),
    NEXTAUTH_URL: z.string().url(),

    SENTRY_CLIENT_DSN: z.string().optional(),
    SENTRY_SERVER_DSN: z.string().optional(),
    SENTRY_EDGE_DSN: z.string().optional(),
    SENTRY_ORG: z.string().optional(),
    SENTRY_PROJECT: z.string().optional(),
    SENTRY_DISABLE_SERVER_WEBPACK_PLUGIN: z.string().optional(),
    SENTRY_DISABLE_CLIENT_WEBPACK_PLUGIN: z.string().optional(),
    SENTRY_IGNORE_API_RESOLUTION_ERROR: z.string().optional(),
  },

  /**
   * The prefix that client-side variables must have. This is enforced both at
   * a type-level and at runtime.
   */
  //clientPrefix: "NEXT_PUBLIC_",

  client: {
    NEXT_PUBLIC_SANITY_ID: z.string(),
    NEXT_PUBLIC_SANITY_DATASET: z.string(),
    NEXT_PUBLIC_KEYCLOAK_REALM: z.string(),
    NEXT_PUBLIC_KEYCLOAK_AUTH_URL: z.string(),
    NEXT_PUBLIC_REN_SYSTEMS_BASE_URL: z.string(),
    NEXT_PUBLIC_APP_URL: z.string(),
    NEXT_PUBLIC_PLAUSIBLE_DOMAIN: z.string(),
  },

  /**
   * What object holds the environment variables at runtime. This is usually
   * `process.env` or `import.meta.env`.
   */
  runtimeEnv: {
    ENVIRONMENT: process.env.ENVIRONMENT,
    DEBUG: process.env.DEBUG,

    SANITY_AUTH_TOKEN: process.env.SANITY_AUTH_TOKEN,
    SANITY_PROXY_FETCH_ENABLED: process.env.SANITY_PROXY_FETCH_ENABLED,
    SANITY_WEBHOOK_SECRET: process.env.SANITY_WEBHOOK_SECRET,

    SANITY_HTMLRENBLAD_TOKEN: process.env.SANITY_HTMLRENBLAD_TOKEN,

    RENBLAD_PRINT_SECRET: process.env.RENBLAD_PRINT_SECRET,
    DOCRAPTOR_API_KEY: process.env.DOCRAPTOR_API_KEY,

    LW_BEARER_TOKEN: process.env.LW_BEARER_TOKEN,
    LW_SCHOOL_URL: process.env.LW_SCHOOL_URL,
    LW_CLIENT_TOKEN: process.env.LW_CLIENT_TOKEN,

    VIPPS_CLIENT_ID: process.env.VIPPS_CLIENT_ID,
    VIPPS_CLIENT_SECRET: process.env.VIPPS_CLIENT_SECRET,
    VIPPS_SUBSCRIPTION_KEY: process.env.VIPPS_SUBSCRIPTION_KEY,
    VIPPS_MERCHANT_SERIAL_NUMBER: process.env.VIPPS_MERCHANT_SERIAL_NUMBER,
    VIPPS_CALLBACK_AUTHORIZATION_TOKEN:
      process.env.VIPPS_CALLBACK_AUTHORIZATION_TOKEN,
    VIPPS_CALLBACK_URL: process.env.VIPPS_CALLBACK_URL,
    VIPPS_RETURN_URL: process.env.VIPPS_RETURN_URL,

    MANDRILL_API_KEY: process.env.MANDRILL_API_KEY,
    MANDRILL_SUBACCOUNT: process.env.MANDRILL_SUBACCOUNT,
    MANDRILL_RETURN_PATH_DOMAIN: process.env.MANDRILL_RETURN_PATH_DOMAIN,
    MANDRILL_SIGNING_DOMAIN: process.env.MANDRILL_SIGNING_DOMAIN,

    EMAIL_VALID_DOMAINS: process.env.EMAIL_VALID_DOMAINS,
    EMAIL_VALID_ADDRESSES: process.env.EMAIL_VALID_ADDRESSES,
    EMAIL_ENABLED: process.env.EMAIL_ENABLED,

    COURSE_DATABASE_URL: process.env.COURSE_DATABASE_URL,

    DB_PASSWORD: process.env.DB_PASSWORD,
    DB_USER: process.env.DB_USER,

    KEYCLOAK_SSR_CONFIG: process.env.KEYCLOAK_SSR_CONFIG,

    NEXTAUTH_SECRET: process.env.NEXTAUTH_SECRET,
    NEXTAUTH_URL: process.env.NEXTAUTH_URL,

    SENTRY_CLIENT_DSN: process.env.SENTRY_CLIENT_DSN,
    SENTRY_SERVER_DSN: process.env.SENTRY_SERVER_DSN,
    SENTRY_EDGE_DSN: process.env.SENTRY_EDGE_DSN,
    SENTRY_ORG: process.env.SENTRY_ORG,
    SENTRY_PROJECT: process.env.SENTRY_PROJECT,
    SENTRY_DISABLE_SERVER_WEBPACK_PLUGIN:
      process.env.SENTRY_DISABLE_SERVER_WEBPACK_PLUGIN,
    SENTRY_DISABLE_CLIENT_WEBPACK_PLUGIN:
      process.env.SENTRY_DISABLE_CLIENT_WEBPACK_PLUGIN,
    SENTRY_IGNORE_API_RESOLUTION_ERROR:
      process.env.SENTRY_IGNORE_API_RESOLUTION_ERROR,

    NEXT_PUBLIC_SANITY_ID: process.env.NEXT_PUBLIC_SANITY_ID,
    NEXT_PUBLIC_SANITY_DATASET: process.env.NEXT_PUBLIC_SANITY_DATASET,
    NEXT_PUBLIC_KEYCLOAK_REALM: process.env.NEXT_PUBLIC_KEYCLOAK_REALM,
    NEXT_PUBLIC_KEYCLOAK_AUTH_URL: process.env.NEXT_PUBLIC_KEYCLOAK_AUTH_URL,
    NEXT_PUBLIC_REN_SYSTEMS_BASE_URL:
      process.env.NEXT_PUBLIC_REN_SYSTEMS_BASE_URL,
    NEXT_PUBLIC_APP_URL: process.env.NEXT_PUBLIC_APP_URL,
    NEXT_PUBLIC_PLAUSIBLE_DOMAIN: process.env.NEXT_PUBLIC_PLAUSIBLE_DOMAIN,
  },

  /**
   * By default, this library will feed the environment variables directly to
   * the Zod validator.
   *
   * This means that if you have an empty string for a value that is supposed
   * to be a number (e.g. `PORT=` in a ".env" file), Zod will incorrectly flag
   * it as a type mismatch violation. Additionally, if you have an empty string
   * for a value that is supposed to be a string with a default value (e.g.
   * `DOMAIN=` in an ".env" file), the default value will never be applied.
   *
   * In order to solve these issues, we recommend that all new projects
   * explicitly specify this option as true.
   */
  emptyStringAsUndefined: true,
});
